<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      light
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <h2 class="text-center font-weight-medium align-center mt-3 mb-3">
            Upload de Arquivos
          </h2>
          <h3>Selecione o arquivo .zip do sistema. ex.: Clinic.zip</h3>
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
            >
              <v-file-input
                v-model="file"
                color="purple"
                show-size
                counter
                label="Selecione o arquivo"
              >
                <template v-slot:selection="{ text }">
                  <v-chip
                    color="purple"
                    dark
                    label
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
            <v-col
              cols="12"
            >
              <h4 class="text-center font-weight-medium align-center mt-3 mb-3">
                Insira a versão
              </h4>
              <v-text-field
                v-model="version"
                outlined
                color="purple"
                label="Versão"
                required
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#E0115F"
            text
            @click="updateDialog(!dialog)"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="file && version"
            color="#4CAF50"
            text
            @click="send()"
          >
            Enviar
          </v-btn>
        </v-card-actions>
        <v-snackbar
          v-model="snackbar"
          multi-line
          color="#0277BD"
          :timeout="timeout"
        >
          <v-row
            justify="center"
          >
            <v-col
              cols="12"
            >
              <h3 class="font-weight-medium text-center align-center mt-3 mb-3">
                {{ snackbarText }}
              </h3>
              <v-progress-linear
                v-if="uploading"
                :value="percentCompleted"
                stream
                rounded
                color="#4CAF50"
                height="25"
              >
                <template v-slot="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import axios from 'axios'
  import filesize from 'filesize'
  export default {
    name: 'UploadFiles',
    props: {
      localDialog: {
        type: Boolean,
        required: true,
      },
      tree: {
        type: Array,
        required: true,
      },
    },
    data: () => {
      return {
        percentCompleted: 0,
        file: null,
        version: '',
        uploading: false,
        snackbar: false,
        snackbarText: '',
        timeout: -1,
      }
    },
    computed: {
      ...mapGetters('files', ['fileList', 'dialogState', 'getDirectories']),
      dialog: {
        get: function () {
          return this.localDialog
        },
        set: function (newValue) {
          this.localDialog = newValue
          this.updateDialog(newValue)
        },
      },
    },
    methods: {
      ...mapActions('atualizacoes', ['updateDialog', 'getUploadUrl']),
      async send () {
        const fileName = this.file.name
        const filePath = `${this.version}/${fileName}`

        console.log('filePath', filePath)

        const uploadUrl = await this.getUploadUrl({ file: filePath })

        const formData = new FormData()
        formData.append('files', this.file)

        this.snackbar = true
        this.uploading = true
        this.snackbarText = 'Aguarde, realizando o upload dos arquivos...'
        const config = {
          responseType: 'arraybuffer',
          onUploadProgress: function (progressEvent) {
            this.percentCompleted = progressEvent.loaded * 100 / progressEvent.total
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        await axios.put(uploadUrl, formData, config)
          .then((response) => {
            this.timeout = 8000
            this.uploading = false
            this.snackbarText = 'O upload de ' + this.files.length + ' arquivo(s) foi concluido!'
            this.files.splice(0, this.files.length)
            setTimeout(() => {
              this.updateDialog(false)
              this.timeout = -1
            }, 2000)
          })
          .catch((err) => {
            this.timeout = 800
            this.uploading = false
            this.snackbarText = 'O upload falhou' + err
            console.error('FAILURE!!', err)
            this.updateDialog(false)
          })
      },
      tamanhoArquivo (val) {
        return filesize(val, { base: 10 })
      },
    },
  }
</script>
